import { HStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import Checkbox from "@pathwright/ui/src/components/form/form-checkbox/Checkbox"
import CheckboxGroup from "@pathwright/ui/src/components/form/form-checkbox/CheckboxGroup"
import { useQuery } from "@pathwright/web/src/modules/utils/apollo"
import { Formik } from "formik"
import get from "lodash/get"
import PropTypes from "prop-types"
import { useMemo } from "react"
import styled from "styled-components"
import { flattenEdges } from "../../utils/apollo"
import PRODUCTS_QUERY from "../graphql/stripe-products-query"

const StyledCheckboxGroup = styled(CheckboxGroup)`
  & > div {
    align-items: flex-start;
  }
`

export const useProducts = ({ includeInactive } = {}) => {
  const productsQuery = useQuery(PRODUCTS_QUERY, {
    variables: { first: 50, includeInactive }
  })
  const products = flattenEdges(get(productsQuery, "data.stripeProducts")) || []

  return { query: productsQuery, products }
}

const ProductCheckboxes = ({
  onChange,
  selectedProductIds,
  includeInactive,
  ...rest
}) => {
  const { products } = useProducts({ includeInactive })
  // Sort inactive products to the bottom.
  const sortedProducts = useMemo(() => {
    return products.sort((productA, productB) => {
      return (productB.active ? 1 : 0) - (productA.active ? 1 : 0)
    })
  }, [products])

  const getUpdatedList = (e, list) => {
    const value = parseInt(e.target.value)
    const index = list.indexOf(value)

    if (index > -1) {
      return [...list.slice(0, index), ...list.slice(index + 1)]
    }
    return [...list, value]
  }

  return (
    <Formik
      initialValues={{
        selectedProductIds
      }}
    >
      {({ values, setValues }) => (
        <StyledCheckboxGroup {...rest}>
          {sortedProducts.map((product) => (
            <Checkbox
              name={`product-${product.id}`}
              type="checkbox"
              key={product.id}
              value={product.id}
              checked={values.selectedProductIds.includes(product.id)}
              onChange={(e) => {
                const list = getUpdatedList(e, values.selectedProductIds)
                onChange(list)
                setValues({
                  selectedProductIds: list
                })
              }}
            >
              <HStack>
                {!product.active && <Pathicon icon="lock" />}
                <span>{product.name}</span>
              </HStack>
            </Checkbox>
          ))}
        </StyledCheckboxGroup>
      )}
    </Formik>
  )
}

ProductCheckboxes.displayName = "ProductCheckboxes"

ProductCheckboxes.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedProductIds: PropTypes.array.isRequired
}

ProductCheckboxes.defaultProps = {
  onChange: () => {},
  selectedProductIds: []
}

export default ProductCheckboxes
