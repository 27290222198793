import { Button } from "@chakra-ui/react"
import React, { forwardRef } from "react"
import styled from "styled-components"
import withTranslate from "../lng/withTranslate"
import Error from "./Error"

// Running into webpack error when using Error directly in styled fn.
function ErrorImportWrapper(props) {
  return <Error {...props} />
}

const StyledError = styled(ErrorImportWrapper)`
  padding: 1em;
`

// client deploys can lead to the loading chunk failures
// if user has stale manifest and triggers a lazy-loaded
// chunk to be fetched
class LoadingChunkErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, showErrorDetails: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    const res = [
      /Loading chunk [0-9a-zA-Z-_]+ failed\./,
      /Loading CSS chunk [0-9a-zA-Z-_]+ failed\./
    ]
    if (res.find((re) => re.test(error.message))) {
      return { error }
    }
  }

  render() {
    if (this.state.error) {
      const { t } = this.props

      return (
        <StyledError
          icon="rocket"
          errorTitle={t("Updates available")}
          errorMessage={t("A newer version is available. Please refresh.")}
          errorDetails={
            this.state.showErrorDetails
              ? JSON.stringify(
                  {
                    message: this.state.error.message,
                    name: this.state.error.name,
                    type: this.state.error.type,
                    request: this.state.error.request
                  },
                  null,
                  2
                )
              : null
          }
          primaryAction={{
            label: t("Refresh"),
            onClick: () => {
              // Add a query param to tell the browser to load the page from the server
              // not from the cache. It seems sometimes the page is cached and users still
              // see this message, hence this workaround. We remove the query param on page load.
              const url = new URL(window.location)
              url.searchParams.append("forceHardRefresh", new Date().getTime())
              window.location = url
            }
          }}
          footer={
            !this.state.showErrorDetails && (
              <Button
                variant="link"
                colorScheme="blackAlpha"
                cursor="pointer"
                bg="none"
                onClick={() =>
                  this.setState((state) => ({
                    ...state,
                    showErrorDetails: true
                  }))
                }
              >
                {t("Expand details")}
              </Button>
            )
          }
        />
      )
    }

    return this.props.children
  }
}

LoadingChunkErrorBoundary.displayName = "LoadingChunkErrorBoundary"

export default withTranslate(LoadingChunkErrorBoundary)
