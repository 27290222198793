import { Button, Link, VStack, useColorModeValue } from "@chakra-ui/react"
import { IconName } from "@pathwright/pathicons"
import { z } from "zod"
import { AuthMethodFragment } from "../../../api/generated"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { useScopedTranslation } from "../../../lib/lng/TranslationsProvider"
import { useAuthContext } from "../api/context"
import { AuthIntentKey } from "../utils/authIntent"
import { useAuthMethods } from "../utils/authMethods"
import {
  getSearchParam,
  mergeSearchParams,
  removeNonTransferrableAuthSearchParamsFromUrl
} from "../utils/getAuthRoute"

const urlSchema = z.string().url()

const primaryOptionTypes = ["saml2", "openid"]

type ExternalAuthMethodUrlProps = {
  authMethod: AuthMethodFragment
  authIntent: AuthIntentKey
  inviteToken?: string | null
  createMembership?: boolean
}

// Append the current url as the ?next param to the authMethod's url so that once the user has
// authenticated via SSO, they will be redirected back, either immediately
// (for previously linked accounts) or after they have completed linking
// their account.
const getExternalAuthMethodUrl = ({
  authMethod,
  authIntent,
  inviteToken,
  createMembership
}: ExternalAuthMethodUrlProps): string => {
  let url = authMethod.meta.url!
  // We don't want to pass on any unwanted auth search params (i.e. "auth_intent", etc.)
  const cleanUrl = encodeURIComponent(
    removeNonTransferrableAuthSearchParamsFromUrl(window.location.href)
  )
  const searchParams = new URLSearchParams([
    // Explicitly telling the backend what the user's auth intent is based
    // on UI context.
    ["intent", authIntent],
    // The URL the backend should redirect in the event the action fails.
    // The backend will append the "auth_intent*" keys to this url.
    ["failure", cleanUrl],
    // The URL the backend should redirect in the event the action succeeds.
    ["next", cleanUrl]
  ])

  // Backend requires invite_token when available for validating user permissions to
  // join Space (in the event that that can occur).
  if (inviteToken) {
    searchParams.set("invite_token", inviteToken)
  }

  // Add the create_membership query param which, when false, prevenst the backend
  // from creating a membership for the user when linking or logging in via global sso.
  if (typeof createMembership !== "undefined") {
    searchParams.set("create_membership", `${createMembership}`)
  }

  // When the user attempts Global SSO form the /auth routes, we don't want to return
  // the user to the /auth routes but to the ?next url, if it exists.
  if (window.location.pathname.startsWith("/auth")) {
    const next = getSearchParam(window.location.href, "next")
    if (next) {
      // At least for now, the backend needs the aboslute ?next url, otherwise relative
      // ?next urls get redirected so sso.pathwright.com.
      const urls = [next, window.location.origin + next]
      const urlParsed = urls
        .map((url) => urlSchema.safeParse(url))
        .find((urlParsed) => urlParsed.success)

      if (urlParsed?.success) {
        searchParams.set("next", urlParsed.data)
      } else {
        searchParams.delete("next")
      }
    }
    if (!next) searchParams.delete("next")
  }

  url = mergeSearchParams(url, searchParams)

  return url
}

type ExternalAuthMethodsProps = {
  authIntent: AuthIntentKey
}

// List the various external auth methods for signing in.
const ExternalAuthMethods = ({ authIntent }: ExternalAuthMethodsProps) => {
  const { inviteToken, createMembership } = useAuthContext()
  const { externalAuthMethods } = useAuthMethods()
  const { t } = useScopedTranslation()
  const colorScheme = useColorModeValue("brand", undefined)

  return externalAuthMethods.length ? (
    <VStack spacing={4} w="100%">
      {externalAuthMethods.map((authMethod) => {
        const variant =
          primaryOptionTypes.indexOf(authMethod.meta.type) > -1
            ? "solid"
            : "outline"
        const leftIcon = authMethod.meta.icon ? (
          <Pathicon icon={authMethod.meta.icon as IconName} />
        ) : undefined

        return (
          <Button
            as={Link}
            variant={variant}
            colorScheme={colorScheme}
            key={authMethod.meta.key}
            href={getExternalAuthMethodUrl({
              authMethod,
              authIntent,
              inviteToken,
              createMembership
            })}
            w="100%"
            leftIcon={leftIcon}
          >
            {t(authMethod.meta.title)}
          </Button>
        )
      })}
    </VStack>
  ) : null
}

export default ExternalAuthMethods
