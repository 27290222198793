import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { RetryLink } from "@apollo/client/link/retry"
import { typePolicies } from "@pathwright/web-new/src/api/cache"
import {
  getIsLocalDev,
  getPathwrightDomain
} from "@pathwright/ui/src/components/utils/env"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
// import cache from "../../../../../client/src/api/cache"
import { getEnv } from "@pathwright/ui/src/components/utils/env"
import ApolloClientStoreBridge from "./ApolloClientStoreBridge"
import { usePathwrightContext } from "./PathwrightContext"

export const getGraphQLEndpoint = () => {
  // returns the proper graphql server endpoint based on the current env

  let isLocalDev = getIsLocalDev()

  let domain = ``
  const pathwrightDomain = getPathwrightDomain()

  if (process.env.REACT_APP_GQL) {
    domain = process.env.REACT_APP_GQL
    isLocalDev = false
  } else if (pathwrightDomain) {
    // one of pathwright's domains
    domain = `gql.${pathwrightDomain}.com`
  } else {
    // custom domain
    domain = "gql.pathwright.com"
  }

  // if (isLocalDev) {
  //   // ROBERT TESTING WHILE TRAVELING
  //   return `https://gql.pathwrightstaging.com/`
  // }

  const PORT = process.env.SERVER_PORT || 8000

  const base = isLocalDev
    ? `http://${window.location.hostname}:${PORT}/`
    : `https://${domain}/`

  return base
}

const getErrorLink = () =>
  onError(({ networkError, graphQLErrors, operation, forward }) => {
    // Removed stored auth_token when invalid.
    if (
      graphQLErrors &&
      graphQLErrors.find(
        ({ message }) =>
          message ===
          `Invalid token. An error occurred while verifying the token: The provided token was revoked.`
      ) &&
      localStorage.getItem("auth_token")
    ) {
      localStorage.removeItem("auth_token")
      return forward(operation)
    }
  })

// This is a singleton pattern
let _client = null

export const getApolloClient = (schoolId, reset = false) => {
  // If in "test" env, attempt to use the window._testClient
  // TODO: should this rather be handled via jest mocks?
  if (getEnv("test") && window._testClient && _client !== window._testClient) {
    _client = window._testClient
  }

  if (_client && !reset) return _client
  if (_client) {
    _client.clearStore()
    _client._consistentReadClient.clearStore()
  }

  const uri = `${getGraphQLEndpoint()}graphql?school_id=${schoolId}`
  const httpLink = createHttpLink({ uri })

  const schoolLink = setContext((_, { headers }) => {
    let nextHeaders = { ...headers }
    nextHeaders.school_id = schoolId
    // return the headers to the context so httpLink can read them
    return {
      headers: nextHeaders
    }
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.auth_token

    let nextHeaders = { ...headers }

    if (token) {
      nextHeaders.authorization = `Bearer ${token}`
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: nextHeaders
    }
  })

  const consistentReadMiddlewareLink = setContext((_, { headers }) => {
    let nextHeaders = { ...headers }
    nextHeaders.ensure_consistent_read = true
    // return the headers to the context so httpLink can read them
    return {
      headers: nextHeaders
    }
  })

  // https://www.apollographql.com/docs/link/links/retry
  // Default is 5 attempts
  const retryLink = new RetryLink()
  const errorLink = getErrorLink()

  const clientOptions = {
    link: from([retryLink, schoolLink, authLink, , errorLink, httpLink]),
    cache: new InMemoryCache({ typePolicies }),
    name: "web",
    version: "1.0"
  }

  _client = new ApolloClient(clientOptions)

  // Optional client used for ensuring GQL server reads data from the main database
  // as opposed to the read replica database.
  // _client._consistentReadClient = new ApolloClient({
  //   ...clientOptions,
  //   link: from([retryLink, consistentReadMiddlewareLink, httpLink])
  // })
  _client._consistentReadClient = _client

  return _client
}

export const usePathwrightClient = () => {
  const { school } = usePathwrightContext()
  const client = getApolloClient(school.id)
  return client
}

export const PathwrightClient = ({ schoolId, children }) => {
  const [client, setClient] = useState(null)

  useEffect(() => {
    // reseting current client, if there is one
    const client = getApolloClient(schoolId, true /* reset */)
    setClient(client)
    // NOTE: setting globally as temporary solution to
    // syncing backbone/mobx stores and apollo client.
    // See apollo client syncer.
    window.apolloClientStoreBridge = new ApolloClientStoreBridge(client)
  }, [schoolId])

  return client ? (
    <ApolloProvider client={client}>
      {typeof children === "function" ? children({ client }) : children}
    </ApolloProvider>
  ) : null
}

PathwrightClient.displayName = "PathwrightClient"

PathwrightClient.propTypes = {
  schoolId: PropTypes.number.isRequired,
  onAuthChange: PropTypes.func
}

export default function withPathwrightClient(ComposedComponent) {
  const ComposedPathwrightClient = ({ schoolId, ...props }) => (
    <PathwrightClient schoolId={schoolId}>
      <ComposedComponent {...props} />
    </PathwrightClient>
  )

  return ComposedPathwrightClient
}
