import { Box, LightMode } from "@chakra-ui/react"
import PasswordChangeViewContainer from "@pathwright/web-new/src/views/auth/password/change/PasswordChangeViewContainer"
import PasswordCreateViewContainer from "@pathwright/web-new/src/views/auth/password/create/PasswordCreateViewContainer"
import { ConnectedProvider as SpaceConnectedProvider } from "@pathwright/web-new/src/views/space/api/context"
import Card from "@pathwright/ui/src/components/card/Card"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import { usePathwrightContext } from "@pathwright/web/src/modules/pathwright/PathwrightContext"

const PasswordCard = ({ card }) => {
  const { t } = useTranslate()
  const { me } = usePathwrightContext()

  return (
    <Card
      // title={t("password_form.card_title")}
      card={card}
    >
      <SpaceConnectedProvider>
        <LightMode>
          <Box className="chakra-ui-reset-css" p="30px" pt={0}>
            {me.no_password_set ? (
              <PasswordCreateViewContainer
                onContinue={() => App.navigate("/manage/profile/")}
              />
            ) : (
              <PasswordChangeViewContainer
                onContinue={() => App.navigate("/manage/profile/")}
              />
            )}
          </Box>
        </LightMode>
      </SpaceConnectedProvider>
    </Card>
  )
}

PasswordCard.displayName = "PasswordCard"

export default PasswordCard
