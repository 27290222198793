import { useMutation } from "@apollo/client"
import { PW_AUTH_TOKEN_KEY } from "@pathwright/web-new/src/api/client"
import { getAuthenticationToken } from "@pathwright/web-new/src/lib/utils/auth-token"
import { getEnv } from "@pathwright/ui/src/components/utils/env"
import moment from "moment"
import { useEffect, useRef } from "react"
import { usePathwrightContext } from "../pathwright/PathwrightContext"
import { getDecodedAuthToken } from "../user/utils"
import REFRESH_TOKEN_MUTATION from "./graphql/refresh-token-mutation"

// useRefreshToken's main concern is to not allow the user's auth token to expire.
// It accomplishes this by refreshing the user's auth token 4 hours in the future or 15 minutes before the token is set to expire, whichever is sooner.

const REFRESH_MINUTES_AFTER = 240 // from now
const REFRESH_MINUTES_BEFORE = 60 // from before expiration

const useRefreshToken = () => {
  const refreshTimeoutRef = useRef(null)
  const { me } = usePathwrightContext()

  const [refreshTokenMutation] = useMutation(REFRESH_TOKEN_MUTATION)

  const refreshToken = async () => {
    const refreshTokenMutationResult = await refreshTokenMutation()
    // Note: quitely storing the new auth token, no need to refetch queries.
    localStorage.setItem(
      PW_AUTH_TOKEN_KEY,
      refreshTokenMutationResult.data.refreshToken
    )
  }

  const validateToken = () => {
    const tokenIssuedAtDate = getAuthTokenDate("iat")
    const tokenExpirationDate = getAuthTokenDate("exp")

    if (!tokenIssuedAtDate) {
      throw new Error(
        "Unable to refresh auth token: Auth token issued date not found."
      )
    }

    if (!tokenExpirationDate) {
      throw new Error(
        "Unable to refresh auth token: Auth token expiration date not found."
      )
    }

    // token is already expired, cannot refresh
    if (moment(tokenExpirationDate).isBefore()) {
      throw new Error("Unable to refresh auth token: Auth token expired.")
    }
  }

  const clearInvalidToken = () => {
    const authToken = localStorage.getItem(PW_AUTH_TOKEN_KEY)
    // For now, try to catch mock dev env where user may exist
    // but no auth token is set (need to fix that).
    if (authToken || !getEnv("development")) {
      localStorage.removeItem(PW_AUTH_TOKEN_KEY)
      // Peform hard refresh. Seems to be the cleanest approach rather than
      // retaining data in cache associated with expired auth token.
      window.location = window.location
    }
  }

  const handleInvalidToken = () => {
    try {
      validateToken()
    } catch (error) {
      clearInvalidToken()
    }
  }

  const handleRefreshToken = async () => {
    try {
      validateToken()
      await refreshToken()
      initRefresh()
    } catch (err) {
      console.error(`Unexpected Error: Failed to refresh token.`, err)
      clearInvalidToken()
    }
  }

  const clearRefreshTimeout = () => {
    refreshTimeoutRef.current && clearInterval(refreshTimeoutRef.current)
    refreshTimeoutRef.current = null
  }

  const getAuthTokenDate = (key) => {
    const decodedAuthToken = getDecodedAuthToken()
    if (decodedAuthToken) {
      const authTokenTime = decodedAuthToken[key]
      try {
        return new Date(authTokenTime * 1000)
      } catch (err) {
        console.error(
          `Unexpected Error: Could not parse "${authTokenTime}" as Date.`,
          err
        )
        return null
      }
    }
    return null
  }

  const initRefresh = () => {
    clearRefreshTimeout()
    handleInvalidToken()

    if (!getAuthenticationToken()) return

    const tokenIssuedAtDate = getAuthTokenDate("iat")
    const tokenExpirationDate = getAuthTokenDate("exp")

    // Get the time at which we should refresh the user's token.
    // This should be the most recent of the 3 dates which will either be:
    // 1) [REFRESH_MINUTES_AFTER] after current date
    const refreshFromNowTime =
      new Date().getTime() + REFRESH_MINUTES_AFTER * 60 * 1000
    // 2) [REFRESH_MINUTES_AFTER] after token issued at date
    const refreshFromIssuedAtTime =
      tokenIssuedAtDate.getTime() + REFRESH_MINUTES_AFTER * 60 * 1000
    // 3) [REFRESH_MINUTES_BEFORE] before token expiration date
    const refreshFromExpiryTime =
      tokenExpirationDate.getTime() - REFRESH_MINUTES_BEFORE * 60 * 1000
    // The miniumn of those 3.
    const refreshTime = Math.min(
      refreshFromNowTime,
      refreshFromIssuedAtTime,
      refreshFromExpiryTime
    )

    // The milliseconds until we should refresh user's token.
    const milliseconds = Math.max(refreshTime - new Date().getTime(), 0)
    refreshTimeoutRef.current = setTimeout(handleRefreshToken, milliseconds)
  }

  useEffect(() => {
    if (me) {
      initRefresh()
    } else {
      clearRefreshTimeout()
    }
    return clearRefreshTimeout
  }, [me?.id])
}

export function RefreshToken() {
  useRefreshToken()
  return null
}

RefreshToken.displayName = "RefreshToken"

export default useRefreshToken
