import { Button, HStack, Heading, Link, Text, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { usePdfDimensions } from "@pathwright/ui/src/components/filestack/utils"
import { Link as ReactRouterLink } from "react-router-dom"
import { useMeasure } from "react-use"
import useActiveCertificate from "../hooks/useActiveCertificate"
import CertificateTemplate from "../template/CertificateTemplate"

const CertificateUpdatePrompt = () => {
  const certificate = useActiveCertificate()
  const { template } = certificate
  const templateDimensions = usePdfDimensions(template)
  const [nodeRef, size] = useMeasure()

  return (
    <VStack spacing={4} padding={4} pt={0} minW="100%" textAlign="center">
      <Pathicon icon="certificate" style={{ fontSize: "24px" }} />
      <Heading as="h3" size="md">
        Configure Completion Certificates
      </Heading>
      <Text textAlign="center">
        Update your Completion Certificate Template and select the Paths where
        it’s used.{" "}
        <Link
          textDecoration="underline"
          textUnderlineOffset={2}
          href="http://help.pathwright.com/en/articles/9211185-provide-a-certificate-of-completion"
          isExternal
        >
          Learn more about certificates.
        </Link>
      </Text>
      <VStack maxW="250px" ref={nodeRef} spacing={0}>
        <CertificateTemplate
          template={template}
          templateDimensions={templateDimensions}
          scaleSize={size}
        />
        <Button
          colorScheme="brand"
          as={ReactRouterLink}
          variant="solid"
          to="/certificate/"
          transform="translateY(-50%)"
          mb="calc(var(--chakra-space-4)* -2)"
        >
          <HStack>
            <Pathicon icon="edit" />
            <span>Update Certificate Template</span>
          </HStack>
        </Button>
      </VStack>
    </VStack>
  )
}

export default CertificateUpdatePrompt
