import { getExternalAuthMethods } from "@pathwright/web-new/src/views/auth/utils/authMethods"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import FeatureBlockUI from "../../feature/block/FeatureBlockUI"
import { useSpaceAuthSettings } from "./ManagePathwrightSignInFeature"

export const useHasEnterpriseSSO = () => {
  const spaceAuthSettingsQuery = useSpaceAuthSettings()

  const hasEnterpriseSSO = getExternalAuthMethods(
    spaceAuthSettingsQuery?.data?.space?.authMethods || []
  ).some(
    (authMethod) =>
      authMethod.enabled && ["saml2", "openid"].includes(authMethod.meta.type)
  )

  return hasEnterpriseSSO
}

const EnterpriseSSOFeatureBlock = (props) => {
  const { t } = useTranslate()
  const hasEnterpriseSSO = useHasEnterpriseSSO()

  const featureInfo = {
    icon: "group-lock",
    title: "Custom SSO",
    desc: "Enable members to sign in with SSO.",
    tooltip: "",
    link: "https://help.pathwright.com/en/articles/1296482-integrate-sso-single-sign-on-with-your-pathwright-account"
  }

  const featurePermissionInfo = hasEnterpriseSSO
    ? {
        icon: "eye",
        title: t("Enabled"),
        titleAbbr: t("Enabled"),
        titleVerbose: t("Enabled")
      }
    : {
        icon: "eye-off",
        title: t("Disabled"),
        titleAbbr: t("Disabled"),
        titleVerbose: t("Disabled")
      }

  return (
    <FeatureBlockUI
      featureInfo={featureInfo}
      featurePermissionInfo={featurePermissionInfo}
      to={`${window.location.pathname}custom-sso/`}
      {...props}
    />
  )
}

EnterpriseSSOFeatureBlock.displayName = "EnterpriseSSOFeatureBlock"

export default EnterpriseSSOFeatureBlock
