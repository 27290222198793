// @ts-ignore
import ThemeBackground from "@pathwright/ui/src/components/background/ThemeBackground"
// @ts-ignore
import SubmitButton from "@pathwright/ui/src/components/button/SubmitButton"
// @ts-ignore
import Card from "@pathwright/ui/src/components/card/Card"
// @ts-ignore
import CardBlock from "@pathwright/ui/src/components/card/CardBlock"
// @ts-ignore
import CardLink from "@pathwright/ui/src/components/card/CardLink"
// @ts-ignore
import ColorPickerToggle from "@pathwright/ui/src/components/form/form-color/ColorPickerToggle"
// @ts-ignore
import FieldWrapper from "@pathwright/ui/src/components/form/form-utils/FieldWrapper"
// @ts-ignore
import Link from "@pathwright/ui/src/components/link/Link"
// @ts-ignore
import { media } from "@pathwright/ui/src/components/utils/styles"

import { Select } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import { Field, Form, Formik } from "formik"
import PropTypes from "prop-types"
import styled from "styled-components"
import { z } from "zod"
import { toFormikValidate } from "zod-formik-adapter"
import {
  ThemeInput,
  ThemeType,
  useCreateSchoolThemeMutation,
  useUpdateSchoolThemeMutation
} from "../../api/generated"
import SchoolIcon from "../../lib/SchoolIcon"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"
import { usePresetThemeFont } from "../../space/signup/SpaceSignupTheme"
import {
  PresetHeadingFontKey,
  formatPresetHeadingFontCss,
  parsePresetHeadingFontCss,
  presetHeadingFontCss,
  presetThemes
} from "../../theme/presets"

const StyledCard = styled(Card)`
  .ColorPickerToggle__toggle > div {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`

const StyledBackground = styled.div`
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    color: white;
    border-bottom: 1px solid white;
  }
  ${media.max.phone`padding-top: 50px;`};
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 10px;
  right: 10px;
`

const StyledIcon = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledFieldWrapper = styled(FieldWrapper)`
  .UIFieldWrapper__input {
    /* For more accurately aboslutely positioning font select input. */
    position: static !important;
  }
`

const BrandPreview = ({ cardPath }: { cardPath: string }) => (
  <StyledBackground>
    <ThemeBackground />
    <StyledLink to={`${cardPath}background/`}>Customize background</StyledLink>
    <StyledIcon>
      <SchoolIcon size={75} className={undefined} />
      <Link to={`${cardPath}icon/`}>Change account icon</Link>
    </StyledIcon>
  </StyledBackground>
)

// Loads the preset theme font.
// Currently doing so when user changes heading font in form,
// and, while we could do this only when they save the form,
// it doesn't seem all that necessary.
// This ensures that when the user saves the form, the newly selected
// heading font will be immediately reflected in the UIs using that font.
function PresetThemeFont({
  headingFont
}: {
  headingFont: PresetHeadingFontKey
}) {
  usePresetThemeFont(headingFont)
  return null
}

type ValidHeadingFont = PresetHeadingFontKey | null | undefined

// Use a "pretty" name for font, mainly resolving ugly "ProximaNova-Regular"
// which we likely should just use a ProximaNova variable font when we have
// time to update.
const PrettyHeadingFontName: Record<PresetHeadingFontKey, string> = {
  Inter: "Inter",
  Newsreader: "Newsreader",
  Montserrat: "Montserrat",
  "General Sans": "General S",
  Pally: "Pally",
  "ProximaNova-Regular": "ProximaNova"
}

const schema = z.object({
  primary_color: z.string().optional(),
  heading_font: z
    .union([
      z.literal("Inter"),
      z.literal("Newsreader"),
      z.literal("Montserrat"),
      z.literal("General Sans"),
      z.literal("Pally"),
      z.literal("ProximaNova-Regular"),
      z.undefined(),
      z.null()
    ])
    .optional() satisfies z.Schema<ValidHeadingFont>
})

const validate = toFormikValidate(schema)

type Schema = z.infer<typeof schema>

type ManageBrandProps = {
  card: any
}

const ManageBrand = ({ card }: ManageBrandProps) => {
  const { school } = usePathwrightContext()
  const initialValues = {
    primary_color: school.theme?.primary_color
      ? `#${school.theme?.primary_color}`
      : `#${presetThemes.friendly.primary_color}`,
    heading_font: school.theme?.heading_font as ValidHeadingFont
  }

  const [createTheme] = useCreateSchoolThemeMutation()
  const [updateTheme] = useUpdateSchoolThemeMutation()
  // TODO: handle css/links
  const handleSubmit = (values: Schema) => {
    if (values.primary_color) {
      values.primary_color = values.primary_color.replace("#", "")
    }

    const prevPresetHeadingFontCss: string | null = initialValues.heading_font
      ? presetHeadingFontCss[initialValues.heading_font]
      : null
    const nextPresetHeadingFontCss: string | null = values.heading_font
      ? presetHeadingFontCss[values.heading_font]
      : null

    let customCss = school.theme?.custom_css || ""

    if (prevPresetHeadingFontCss !== nextPresetHeadingFontCss) {
      // Parse out previous font css.
      if (prevPresetHeadingFontCss) {
        customCss = parsePresetHeadingFontCss(
          initialValues.heading_font as PresetHeadingFontKey,
          customCss
        )
      }
      // Insert new font css.
      if (nextPresetHeadingFontCss) {
        customCss += `\n${formatPresetHeadingFontCss(
          values.heading_font as PresetHeadingFontKey
        )}`
      }
    }

    const themeInput: ThemeInput = {
      ...values,
      custom_css: customCss
    }

    // Defaulting to using the legacy theme.
    themeInput.theme_type = school.theme
      ? school.theme.theme_type
      : ThemeType.Legacy

    return Promise.all([
      school.theme
        ? updateTheme({
            variables: {
              id: school.theme.id!,
              theme: themeInput
            }
          })
        : createTheme({
            variables: {
              theme: themeInput
            }
          })
    ])
  }

  return (
    <StyledCard card={card} title="Branding">
      <BrandPreview cardPath={card.path} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnMount
      >
        {(form) => {
          return (
            <Form>
              {!!form.values.heading_font &&
                form.values.heading_font !== initialValues.heading_font && (
                  <PresetThemeFont headingFont={form.values.heading_font} />
                )}
              <CardBlock>
                <Field
                  name="heading_font"
                  as={(props: any) => (
                    <StyledFieldWrapper
                      label="Heading Type Face"
                      helperText="Select the default font to use for headings."
                      labelWidth={180}
                      style={{ width: "100%" }}
                    >
                      <Select
                        icon={<Pathicon icon="chevron-down" />}
                        placeholder={
                          !initialValues.heading_font ? "Select font" : ""
                        }
                        w="160px"
                        ml="auto"
                        // Simply remove from layout to avoid pushing helper text down.
                        pos="absolute"
                        top={0}
                        right={0}
                        {...props}
                      >
                        {Object.keys(presetHeadingFontCss).map((fontName) => (
                          <option key={fontName} value={fontName}>
                            {
                              PrettyHeadingFontName[
                                fontName as PresetHeadingFontKey
                              ]
                            }
                          </option>
                        ))}
                      </Select>
                    </StyledFieldWrapper>
                  )}
                />
              </CardBlock>
              <CardBlock borderTop borderBottom>
                <Field
                  name="primary_color"
                  label="Primary color"
                  helperText="Your primary color shows up on buttons, links, and accents."
                  labelWidth={180}
                  as={ColorPickerToggle}
                  format="hex"
                  alignRight
                  style={{ width: "100%" }}
                  onChange={(value: string) => {
                    form.setFieldValue("primary_color", value, true)
                  }}
                />
              </CardBlock>
              <SubmitButton
                styleType="primary"
                color={initialValues.primary_color}
                submitting={form.isSubmitting}
                disabled={!form.isValid}
              >
                Save
              </SubmitButton>
            </Form>
          )
        }}
      </Formik>
      <CardLink
        icon="settings-horizontal"
        label="Add custom CSS (advanced)"
        to={`${card.path}css/`}
      />
    </StyledCard>
  )
}

ManageBrand.displayName = "ManageBrand"

ManageBrand.propTypes = {
  // provided by CardStack > CardStackCard
  card: PropTypes.object.isRequired,
  // handler to save field values
  onSubmit: PropTypes.func.isRequired
}

export default ManageBrand
