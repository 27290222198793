import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  HStack,
  Heading,
  LightMode,
  Link,
  Text,
  UseRadioGroupReturn,
  VStack,
  useRadio,
  useRadioGroup
} from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import type { IconName } from "@pathwright/pathicons"
import { NumberParam, useQueryParam } from "use-query-params"
import {
  SchoolType,
  SpaceBillingPlanSetPlanFragment,
  useSpaceBillingPlanSetQuery,
  useSpaceSsoLinkQuery,
  useUpdateSpaceProfileMutation
} from "../../api/generated"
import { BILLING_INTERVAL_YEAR } from "../../billing/constants"
import { useStripeOrder } from "../../order/OrderStripeCheckoutForm"
import { usePathwrightContext } from "../../pathwright/PathwrightContext"

type ProfileOption = {
  icon: IconName
  label: string
}

type ProfileOptions = Record<
  Exclude<SchoolType, SchoolType.Unspecified>,
  ProfileOption
>

const schoolTypesMap: ProfileOptions = {
  training: {
    icon: "connections",
    label: "Team Training"
  },
  coach_tutor: {
    icon: "whistle",
    label: "Coaching / Tutoring"
  },
  non_profit: {
    icon: "smile", // ?
    label: "Non Profit"
  },
  professional_development: {
    icon: "certificate",
    label: "Professional Development"
  },
  curriculum_publisher: {
    icon: "files",
    label: "Online Courses"
  },
  higher_ed: {
    icon: "grad-cap",
    label: "Higher Education"
  },
  customer_training: {
    icon: "window-smile",
    label: "Customer Training"
  },
  ministry: {
    icon: "church",
    label: "Religious Education"
  },
  k12: {
    icon: "apple",
    label: "K-12 Classroom"
  },
  other: {
    icon: "shapes",
    label: "Other"
  }
}

function ProfileOption({
  icon,
  label,
  ...radioProps
}: ProfileOption & ReturnType<UseRadioGroupReturn["getRadioProps"]>) {
  const { getInputProps, getRadioProps } = useRadio(radioProps)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as="label" cursor="pointer">
      <input {...input} />
      <Button
        {...checkbox}
        as={Box}
        variant="outline"
        size="sm"
        px={5}
        py={3}
        borderColor={radioProps.isChecked ? "white" : undefined}
        leftIcon={<Pathicon icon={icon} />}
      >
        {label}
      </Button>
    </Box>
  )
}

type StripOrderPromptProps = {
  spaceId: number
  plan: SpaceBillingPlanSetPlanFragment
  stripeApiKey: string
}

function StripOrderPrompt({
  spaceId,
  plan,
  stripeApiKey
}: StripOrderPromptProps) {
  const [planInterval] = useQueryParam("interval", NumberParam)
  const defaultPlanInterval = BILLING_INTERVAL_YEAR

  const { handleOrder, loading, error } = useStripeOrder({
    orderType: "createPlatformSubscriptionOrder",
    orderData: {
      billing_plan_id: plan.id,
      interval: planInterval || defaultPlanInterval,
      schoolId: spaceId
    },
    stripeApiKey: stripeApiKey
  })

  return (
    <VStack>
      <LightMode>
        <Button
          borderRadius="50px"
          size="lg"
          color="brand.500"
          onClick={handleOrder}
          isLoading={loading!}
        >
          Try with the {plan.name} Plan
        </Button>
      </LightMode>
      <Text size="xs" textAlign="center">
        Access the features for the {plan.name} plan. (All plans include a
        30-day free trial.)
      </Text>
      {!!error && (
        <LightMode>
          <Alert status="error" mt={2}>
            <AlertIcon />
            <Box>
              <AlertTitle>Oops! Something went wrong.</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Box>
          </Alert>
        </LightMode>
      )}
    </VStack>
  )
}

type SpaceCompletionFormProps = {
  spaceId: number
  spaceProfileId: number
  stripeApiKey: string
}

function SpaceCompletionForm({
  spaceId,
  spaceProfileId,
  stripeApiKey
}: SpaceCompletionFormProps) {
  const { me } = usePathwrightContext()
  const [planId] = useQueryParam("plan_id", NumberParam)

  const plans = useSpaceBillingPlanSetQuery({ skip: !planId }).data?.account
    ?.billing_plan_set?.plans
  const selectedPlan = planId
    ? plans?.find((plan) => plan!.id === planId)
    : null

  const [updateSpaceProfileMutation, updateSpaceProfileMutationState] =
    useUpdateSpaceProfileMutation()

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "profile",
    // defaultValue: undefined,
    onChange: (schoolType: SchoolType) => {
      // Update the school profile immediately.
      updateSpaceProfileMutation({
        variables: {
          id: spaceProfileId,
          school_type: schoolType
        }
      })
    }
  })

  const group = getRootProps()

  const activateSSOLink = useSpaceSsoLinkQuery({
    variables: {
      spaceId,
      pathname: "/pricing/plan/"
    }
  }).data?.context?.school?.sso_link!
  const teachSSOLink = useSpaceSsoLinkQuery({
    variables: {
      spaceId,
      pathname: "/home/learn/"
    }
  }).data?.context?.school?.sso_link!

  return (
    <VStack m={2} p={4} spacing={4} justifyContent="center" w="100%">
      <Heading as="h1" size="xl" fontFamily="theme.heading" textAlign="center">
        Welcome to Pathwright, {me.first_name}.
      </Heading>
      <Text textAlign="center">
        What kinds of learning Paths are you creating? (Select one and we’ll
        provide personalized tips.)
      </Text>

      <HStack
        {...group}
        mt={4}
        mb={8}
        flexWrap="wrap"
        justifyContent="center"
        spacing={4}
      >
        {Object.entries(schoolTypesMap).map(([profileKey, { icon, label }]) => {
          const radio = getRadioProps({ value: profileKey })
          return (
            <ProfileOption
              key={profileKey}
              icon={icon}
              label={label}
              {...radio}
            />
          )
        })}
      </HStack>

      <VStack spacing={12} p={4}>
        {selectedPlan ? (
          <StripOrderPrompt
            spaceId={spaceId}
            plan={selectedPlan}
            stripeApiKey={stripeApiKey}
          />
        ) : (
          <VStack>
            <LightMode>
              <Button
                as={Link}
                borderRadius="50px"
                size="lg"
                color="brand.500"
                href={activateSSOLink}
                _hover={{
                  textDecoration: "none"
                }}
              >
                Try with a Plan
              </Button>
            </LightMode>
            <Text size="xs" textAlign="center">
              To use premium features starting at $99/mo. (All plans include a
              30-day free trial.)
            </Text>
          </VStack>
        )}

        <VStack>
          <LightMode>
            <Button
              as={Link}
              borderRadius="50px"
              size="lg"
              color="brand.500"
              href={teachSSOLink}
              _hover={{
                textDecoration: "none"
              }}
            >
              Start without a Plan
            </Button>
          </LightMode>
          <Text size="xs" textAlign="center">
            Free. No credit card required. Upgrade anytime.
          </Text>
        </VStack>
      </VStack>
    </VStack>
  )
}

export default SpaceCompletionForm
