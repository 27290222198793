import { Box, DarkMode, HStack, Input, Text, VStack } from "@chakra-ui/react"
import Pathicon from "@pathwright/web/src/modules/pathicon/Pathicon"
import BackgroundImage from "@pathwright/ui/src/components/background/BackgroundImage"
import ResourceBackground from "@pathwright/ui/src/components/background/ResourceBackground"
import Button from "@pathwright/ui/src/components/button/Button"
import { useTranslate } from "@pathwright/ui/src/components/lng/withTranslate"
import Tooltip from "@pathwright/ui/src/components/tooltip/Tooltip"
import View from "@pathwright/ui/src/components/ui/View"
import { TERTIARY_GRAY } from "@pathwright/ui/src/components/utils/colors"
import PropTypes from "prop-types"
import { useImperativeHandle, useRef, useState } from "react"
import {
  RESOURCE_TYPE_COLLECTION,
  RESOURCE_TYPE_COURSE
} from "../../resource/constants"
import { getResourceTypeLabel } from "../../resource/utils"

export const getCohortPlaceholder = (translator) => {
  // get the first day of a year, the very first milisecond of it
  const getDate = (year) => new Date(year, 0, 1, 0, 0, 0, 0)
  const currentYear = new Date().getFullYear()

  const startDate = getDate(currentYear)
  const endDate = getDate(currentYear + 1)
  // get the toal miliseconds in the year
  const yearInMiliseconds = endDate.getTime() - startDate.getTime()

  const placeholders = [
    translator("Spring {{ currentYear }}", { currentYear }),
    translator("Summer {{ currentYear }}", { currentYear }),
    translator("Fall {{ currentYear }}", { currentYear }),
    translator("Winter {{ currentYear }}", { currentYear })
  ]

  // find the placeholder that falls within the expected time range
  const currentPlaceholder = placeholders.find((placeholder, i) => {
    const endDate = new Date(
      // check each each segment of the year as it pertains to each placeholder
      startDate.getTime() + (yearInMiliseconds / placeholders.length) * (i + 1)
    )

    // if the current time occurs before the upcoming segment, we want the corresponding placeholder
    if (new Date().getTime() < endDate.getTime()) {
      return placeholder
    }
  })

  return currentPlaceholder
}

const CohortNameInput = React.forwardRef(
  (
    { resourceType, onChangeCohortName, selectedResource, resourceCoverImage },
    ref
  ) => {
    const inputRef = useRef(null)
    const [cohortName, setCohortName] = useState("")
    const { t, tc } = useTranslate()

    // Passing cohort name input ref back up the tree for auto focusing purposes.
    useImperativeHandle(ref, () => inputRef.current)

    const tooltip = t(
      `A Cohort is a group of people taking a {{ resourceType }} together with a shared schedule, community, and more.`,
      {
        resourceType: getResourceTypeLabel(resourceType, tc)
      }
    )

    return (
      <Box className="CohortNameInput" w="100%">
        <View.Secondary
          padding
          rounded
          style={{ position: "relative", backgroundColor: TERTIARY_GRAY }}
        >
          {selectedResource ? (
            <>
              <ResourceBackground rounded resource={selectedResource} />
              <BackgroundImage rounded overlay={`rgba(0,0,0, .33)`} />
            </>
          ) : resourceCoverImage ? (
            <BackgroundImage
              rounded
              image={resourceCoverImage}
              overlay={`rgba(0,0,0, .33)`}
            />
          ) : null}
          <VStack alignItems="flex-start">
            <HStack pos="relative" color="white">
              <Pathicon icon="group" />
              <Text as="span" color="white">
                {t("Cohort")}
              </Text>
              <Tooltip
                title={
                  <>
                    {tooltip}
                    <br />
                    <br />
                    <Button
                      styleType="text"
                      href="https://help.pathwright.com/en/articles/459249-multiple-cohorts-unleash-your-content"
                      target="_blank"
                      inverted
                    >
                      {t("Learn more")} <Pathicon icon="external-link" />
                    </Button>
                  </>
                }
              >
                <Pathicon icon="info-circle" />
              </Tooltip>
            </HStack>
            <DarkMode>
              <Input
                ref={inputRef}
                type="text"
                inverted
                value={cohortName}
                onChange={(e) => {
                  const { value } = e.target
                  setCohortName(value)
                  onChangeCohortName(value)
                }}
                placeholder={getCohortPlaceholder(t)}
                bg="none"
                borderColor="whiteAlpha.600"
                color="white"
                caretColor="white"
                _placeholder={{
                  color: "whiteAlpha.600"
                }}
                _focus={{
                  bg: "whiteAlpha.300"
                }}
              />
            </DarkMode>
          </VStack>
        </View.Secondary>
      </Box>
    )
  }
)

CohortNameInput.displayName = "CohortNameInput"

CohortNameInput.propTypes = {
  resourceType: PropTypes.oneOf([
    RESOURCE_TYPE_COLLECTION,
    RESOURCE_TYPE_COURSE
  ]),
  onChangeCohortName: PropTypes.func.isRequired,
  selectedResource: PropTypes.object,
  resourceCoverImage: PropTypes.string
}

export default CohortNameInput
